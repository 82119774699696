@font-face { font-family: 'Griffon'; src: url('./fonts/GriffonShadowNormal.ttf') format('truetype'); font-weight: 300; font-style: normal; }

* {
  margin: 0;
  padding: 0;
}

.wrapper {
  font-family: Arial, sans-serif;
  background-image: url('./img/under_construction.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.container {
  font-size: 2vw;
  max-width: 100%;
  margin: auto;
  padding-top: 150px;
  text-align: center;
  color: #fff;
}

.footer {
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin-left: 70px;
  margin-bottom: 100px;
  background-color: lightgray;
  border-radius: 15px;
  padding: 10px 30px;
  opacity: .5;
}

.footer p {
  margin: auto;
  position: static;
  bottom: 0;
  text-align: center!important;
}

.company_name {
  font-family: 'Griffon';
  font-size: 4vw;
  color: black;
  margin: 0;
  text-align: center;
}
